import React, { useState, useEffect, useContext } from 'react';
import {useTranslation} from "react-i18next";
/* Material UI */
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
/* Components */
import Counter from "../Counter";
import ACLWrapper from '../../../Containers/ACLWrapper';
/* Global state */
import { OrderContext, OrderActionsContext } from '../../../Context/OrderContext';
import { UPDATE_ORDER } from '../../../Context/reducers/orderReducer';

/* Styles */
const styles = {
    row: {
        backgroundColor: 'action.hover',
    },
    selectedRow: {
        backgroundColor: 'primary.light',
    },
    browserName: {
        fontWeight: 600
    },
    expired: {
        color: 'common.red',
    },
    active: {
        color: 'common.green',
    },
    beforeExpiration: {
        color: 'common.orange',
    },
};


const BrowsersTableRow = ({ value, browser }) => {
    
    const { t } = useTranslation();
    const currentOrderState = useContext(OrderContext);
    const dispatchOrder = useContext(OrderActionsContext);
    const [ counter, setCounter ] = useState(
        currentOrderState.browsers.order.find(orderItem => orderItem.browser_id === browser.browser_id) ? currentOrderState.browsers.order.find(orderItem => orderItem.browser_id === browser.browser_id).services[0].months : 0);
    const [ noLimit, setNoLimit ] = useState(false);

    const today = new Date();
    const dateTo = new Date(browser.date_to);
    const expired = today > dateTo;

    const resolvedMonth = expired ? today.getMonth() + 1 : dateTo.getMonth() + 1;
    const maxPossibleAddedMonths = resolvedMonth === 11
        ? 6
        : resolvedMonth === 12
            ? 5
            : resolvedMonth === 1
                ? 4
                : resolvedMonth === 2
                    ? 3
                    : resolvedMonth === 3
                        ? 2
                        : resolvedMonth === 3
                            ? 1
                            : 0

    const sevenDaysFromToday = new Date();
    sevenDaysFromToday.setDate(sevenDaysFromToday.getDate() + 7);

    /**
     * UPDATE VALUE IN ORDER CONTEXT
     * @param {*} value 
     */
    const dispatchValue = (value) => {
        dispatchOrder({
            type: UPDATE_ORDER,
            category: "browsers",
            orderItem: {
                browser_id: browser.browser_id,
                browser_name: browser.browser_name,
                date_to: browser.date_to,
                services: [
                    {
                        service_name: "Browser",
                        months: (value),
                    }
                ] 
            }
        })
    }
    
    /**
     * INCREMENT VALUE FOR REMOTE MANAGEMENT
     */
    const handleIncrement = () => {
        if (counter < maxPossibleAddedMonths) { // Order can be made only until the end of 2024, edit: march 2025, edit2: april 2025, edit3: may 2025
            dispatchValue(counter + 1);
            setCounter(counter + 1);
        }
    }

    /**
     * DECREMENT VALUE FOR REMOTE MANAGEMENT
     */
    const handleDecrement = () => {
        if (counter > 0) {
            dispatchValue(counter - 1);
            setCounter(counter - 1);
        } 
    }

    /**
     * HANDLE INPUT VALUE FOR COUNTER
     * @param {*} event 
     * @returns 
     */
    const handleInputChange = (event) => {
        if (parseInt(event.target.value) || event.target.value === "0" || event.target.value === ""){
            let inputValue = !(event.target.value === "0" || event.target.value === "") ? parseInt(event.target.value) : 0;
            if (inputValue > maxPossibleAddedMonths) // Order can be made only until the end of 2024, edit: march 2025, , edit2: april 2025, edit3: may 2025
                inputValue = maxPossibleAddedMonths;
            dispatchValue(inputValue);
            setCounter(inputValue);
        }
        else {
            return;
        }
    }
    /**
     * Check for dismiss order
     */
    useEffect(() => {
       if (currentOrderState.browsers.price === 0 && currentOrderState.browsers.order.length === 0)
            setCounter(0);
    }, [currentOrderState.browsers])

    return (
        <TableRow sx={styles.row} key={browser.browser_id}>
            {/* Checkbox */}
            <TableCell component="th" scope="row">
                
            </TableCell>

            {/* Browser name */}
            <TableCell>
                <Typography sx={styles.browserName} color="primary" gutterBottom>
                    {browser.browser_name.toUpperCase()}
                </Typography>
            </TableCell>

            <TableCell align='center'>
                {new Date() > new Date(browser.date_to) ? 
                    <Typography sx={styles.expired} gutterBottom>
                        <Box fontWeight="fontWeightBold">
                            {t("services.common.label.expired")}
                            <br />
                            ({t("services.common.label.since")} {browser.date_to})
                        </Box>
                    </Typography>
                    : 
                    <Typography
                        sx={new Date(browser.date_to) > sevenDaysFromToday ? styles.active : styles.beforeExpiration}
                        gutterBottom
                    >
                        <Box fontWeight="fontWeightBold">
                            {t("services.common.label.active")}
                            <br />
                            ({t("services.common.label.until")} {browser.date_to})
                        </Box>
                    </Typography>
                }
            </TableCell>

            {/* Number of months */}
            <TableCell align="center">
                <Counter 
                    disabled={noLimit || counter === 1200} 
                    value={counter}
                    handleIncrement={handleIncrement}
                    handleDecrement={handleDecrement}
                    handleInputChange={handleInputChange}
                />
            </TableCell>

            {/* Data */}
            {/*<TableCell align="center">
                <Counter 
                    disabled 
                />
                <Typography variant="subtitle2" color="secondary">
                    <Box fontWeight="fontWeightBold">
                        {t('services.browsersTable.label.notAvailable')}
                    </Box>
                </Typography>
            </TableCell>*/}
            
            <ACLWrapper requiredPermission={'ACL_ADMIN'}>
                <TableCell align="center"> 
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={noLimit || counter === 1200} 
                                onChange={() => {
                                    if (noLimit) {
                                        setNoLimit(false);
                                        setCounter(0);
                                        dispatchValue(0);
                                    }
                                    else {
                                        setNoLimit(true);
                                        setCounter(1200);
                                        dispatchValue(1200);
                                    }
                                }}
                                name="checkAll" 
                                color="primary"
                            />
                        }
                        label={t('services.browsersTable.label.noLimit')}
                    />
                </TableCell>
            </ACLWrapper>
        </TableRow>
    );
}

export default BrowsersTableRow;
